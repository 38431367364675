<template>
  <div class="car">
    <CssCar/>
  </div>
</template>

<script>
import CssCar from "@/components/CssCar";
export default {
  name: 'Car',
  components: {
    CssCar
  }
}
</script>
